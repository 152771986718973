import request from '@/utils/request'

/**
   * 录入游客
  **/
export const addTourist = (data) => request({
    method:'POST',
    url:'/business/tourist/addTourist',
    data
})
/**
   * 游客列表
  **/
export const getTourist = (params) => request({
    method:'GET',
    url:'/business/tourist/touristList',
    params
})

/**
   * 添加收藏
  **/
export const addCollectible = (data) => request({
    method:'POST',
    url:'/business/collectible/addCollectible',
    data
})
/**
   * 取消收藏
  **/
export const delCollectible  = (data) => request({
  method:'POST',
  url:'/business/collectible/delCollectible ',
  data
})
/**
   * 收藏列表
  **/
export const collectList  = (params) => request({
  method:'get',
  url:'/business/collectible/list',
  params
})
/**
   * 用户授权
  **/
export const getOpenId  = (params) => request({
  method:'POST',
  url:'/pay/wechatPay/auth',
  params
})
/**
   * 删除用户
  **/
export const deleteOpen  = (params) => request({
  method:'delete',
  url:'/business/tourist/'+params,
})