import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router)

//缓存原来的push、replace
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace

//增强push
Router.prototype.push = function(location,okCallback,errCallback){
if(okCallback === undefined && errCallback === undefined){
	return originalPush.call(this,location).catch(()=>{})
}else{
	return originalPush.call(this,location,okCallback,errCallback)
}
}
//增强replace
Router.prototype.replace = function(location,okCallback,errCallback){
if(okCallback === undefined && errCallback === undefined){
	return originalReplace.call(this,location).catch(()=>{})
}else{
	return originalReplace.call(this,location,okCallback,errCallback)
}
}


let routers = new Router({
    mode: 'history',
    routes: [
      {
        path:"/",
        name:'index',
        component: () => import('../views/index')
      },
      {
        path:"/goPay",
        name:'confirmOrder',
        component: () => import('../views/confirmOrder')
      },
      {
        path:"/user",
        name:'user',
        component: () => import('../views/user')
      },
      {
        path:"/order",
        name:'order',
        component: () => import('../views/order')
      },
      {
        path:"/order_detail",
        name:'orderDetail',
        component: () => import('../views/orderDetail')
      },
      {
        path:"/buy",
        name:'buy',
        component: () => import('../views/buy')
      },{
        path:"/pay_success",
        name:'pay_success',
        component: () => import('../views/order/pay_success')
      },
      {
        path:"/orderSubmit",
        name:'orderSubmit',
        component: () => import('../views/orderSubmit')
      },
    ]
      
      
  })
  export default routers