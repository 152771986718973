import Vue from 'vue'
import App from './App.vue'
import router from './router'
import wx from 'weixin-js-sdk'
import Vant from 'vant';
import 'vant/lib/index.css';
import VueClipboard from 'vue-clipboard2'
 
Vue.use(VueClipboard)

Vue.use(Vant);

Vue.config.productionTip = false

Vue.prototype.$wx = wx

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
