<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getOpenId } from "@/api/public";
export default {
  name: "App",
  data() {
    return {
      code: "",
    };
  },
  methods: {
    wx() {
      //  this.$wx.config({
      //     appId: res.data.appId || "wxf6f6562c583b08cf", // 必填，公众号的唯一标识
      //     debug: false,
      //     timestamp: res.data.timestamp, // 必填，生成签名的时间戳
      //     nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
      //     signature: res.data.signature, // 必填，签名
      //     jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"], // 必填，需要使用的 JS 接口列表
      //     openTagList: ["wx-open-launch-app"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      //   });
      //   jweixin.ready(function () {
      //     // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中
      //     console.log("success");
      //   });
      //   jweixin.error(function (res) {
      //     // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名
      //     console.log("error");
      //   });
    },
    getcode(appId, redirect_uri) {
      this.code = this.getUrlcode().code;
      //  获取code
      // let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&scope=snsapi_userinfo&state=STATE&redirect_uri=${redirect_uri}`;
      if (this.code == null || this.code === "") {
        let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        location.href = url;
      } else {
        getOpenId({ code: this.code }).then((res) => {
          if (res.code == 200) {
            let userID = res.data.openid;
            // alert( JSON.stringify(res.data))
            localStorage.setItem("openId", userID);
            localStorage.setItem("userInfo", JSON.stringify(res.data));
          }
        });
      }
    },
    getUrlcode() {
      // 截取code
      let url = location.search;
      let newObj = {};
      if (url.indexOf("?") != -1) {
        let hash = url.split("?")[1];
        let newHash = hash.split("&");
        console.log(hash, newHash);
        for (var i in newHash) {
          var arr = newHash[i].split("=");
          newObj[arr[0]] = arr[1];
        }
      }
      return newObj;
    },
  },
  mounted() {
    //本地调试
    // localStorage.setItem("openId", 'o-Na95-9u5bjo-bHvPoDwAR4PiqI');
    // localStorage.clear()
    //生产
    let local = window.location.href;
    let openId = localStorage.getItem("openId");
    if (typeof(openId)=='undefined'||!openId) {
      let appId = "wxfec9da683d9a32f8";
      let redirect_uri = encodeURIComponent(local);
      this.getcode(appId, redirect_uri);
    }
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
}
</style>
